import React, { Component } from "react";
import LogButton from "../auth/LogButton";
import NotificationDropdown from "./NotificationDropdown";
import history from "../../../history";

class Navbar extends Component {
    toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }
    render() {
        return (
            <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
                    <button
                        className="navbar-brand brand-logo-mini align-self-center d-lg-none btn"
                        onClick={() => history.push("/dashboard")}
                    >
                        <img
                            src={require("../../../assets/images/logo-mini-dark.jpg")}
                            alt="logo"
                        />
                    </button>
                    <ul className="navbar-nav navbar-nav-right ml-lg-auto">
                        <NotificationDropdown />
                        <LogButton />
                    </ul>
                    <button
                        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        onClick={this.toggleOffcanvas}
                    >
                        <span className="fas fa-bars"></span>
                    </button>
                </div>
            </nav>
        );
    }
}

export default Navbar;
