import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import RenderRole from "../shared/RenderRole";
import DiskUsage from "../shared/DiskUsage";
import history from "../../../history";
import Can from "./Can";
import { getFullName } from "../../helpers/clientHelpers";

class LogButton extends Component {
    render() {
        if (this.props.user !== null) {
            return (
                <li className="nav-item nav-profile not-navigation-link">
                    <div className="nav-link">
                        <Dropdown>
                            <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                                <div className="d-flex flex-column align-items-center">
                                    <p className="profile-name mt-4">
                                        {getFullName(this.props.user)}
                                    </p>
                                    <RenderRole
                                        role={this.props.user.role}
                                        className="m-0"
                                    />
                                    <Can perform="diskusage:see">
                                        <DiskUsage />
                                    </Can>
                                    {this.props.user.currentDossier &&
                                        this.props.user.currentDossier.nom && (
                                            <Can perform="showClientDashboard">
                                                <div className="mt-4">
                                                    Dossier :{" "}
                                                    {
                                                        this.props.user
                                                            .currentDossier.nom
                                                    }
                                                </div>
                                            </Can>
                                        )}
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="preview-list navbar-dropdown">
                                <Dropdown.Item
                                    className="dropdown-item preview-item d-flex align-items-center text-small"
                                    onClick={evt => {
                                        evt.preventDefault();
                                        history.push("/profil");
                                    }}
                                >
                                    Mon profil
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="dropdown-item preview-item d-flex align-items-center text-small"
                                    href="/api/auth/logout"
                                >
                                    Déconnexion
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </li>
            );
        }
        return null;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth,
    };
};

export default connect(mapStateToProps)(LogButton);
